import _ from 'underscore'
import {toKeys, toUniqueArr} from "./TopStats";
import {generateHistorySource, toRub} from "../methods/GenerateHistory";
import {STATUS_ITEMS} from "./ZadachiStatuses";

export function multiSave(items, cb, ecb) {

}

export const generateHistory = generateHistorySource

export function getVirtualAccount() {
    return window.info.accounts.find(it => it.type == 'virtual')
}


export function saveTransaction(item, cb, ecb) {
    // multiSave([item], cb, ecb)
    global.http.put('/transaction', item).then(r => {
        cb && cb(r)
    }).catch(e => {
        ecb && ecb(e)
    })
}

export function createTransaction(item, cb, ecb) {
    global.http.post('/transaction', item).then(r => {
        cb && cb(r)
    }).catch(e => {
        ecb && ecb(e)
    })
}

export function getRate(currency, period) {
    return 100;
}

// export function getStats(transactions, keysFn, opts) {
//     let {woItems} = opts || {}
//
//     let stats = {};
//     let teamsObj = {};
//
//     let onPush = (obj, key, item, total) => {
//         console.log('*........ ## on PUsh',item);
//         _.each([key, 'total'], (key, ind) => {
//             key = key || '';
//             // if (key) {
//             total = +total || 0;
//             obj[key] = obj[key] || {items: [], totals: [], total: 0, totalFrom: 0, totalTo: 0}
//             !woItems && obj[key].items.push(item);
//             obj[key].totals.push(total);
//             obj[key].totalFrom += (total < 0 ? total : 0);
//             obj[key].totalTo += (total > 0 ? total : 0);
//             obj[key].total += (total || 0);
//
//         })
//
//
//     };
//
//     _.each(transactions, (it, ind) => {
//         let {
//             type,
//             accountFrom,
//             teamFrom,
//             teamTo,
//             periodsCount,
//             accountTo,
//             status,
//             amountFrom,
//             amountTo,
//             amountFromMonthly,
//             amountToMonthly
//         } = it;
//         let keys = keysFn(it);
//         teamsObj[teamFrom] = true;
//         teamsObj[teamTo] = true;
//
//
//         amountFrom = -amountFrom;
//
//         _.each(keys, (key, ind) => {
//             stats[key] = stats[key] || {}
//             onPush(stats[key], teamFrom, it, amountFrom)
//             onPush(stats[key], teamTo, it, amountTo)
//         })
//
//         // onPush(stats, 'any', it, total)
//         //
//         //
//         // onPush(byTypeStatus, `${type}_${status}`, total)
//
//         // byTypes[type] ??= {items: [], total: 0}
//
//     })
//
//     stats.teams = Object.keys(teamsObj).filter(it => it && it !== 'undefined')
//     return stats
// }


export function getStats2(transactions, keysFn, opts) {
    let {withTotals, withItems, amountKey, onPushFn, filterFinalItem, mult = 1} = opts || {}

    let stats = {};
    let contractsObj = {};
    let teamsObj = {};


    let onPush = (_obj, key, team, item, total, details) => {
        if (!team) {
            return;
        }

        _.each([team, 'total'], (team, ind) => {
            _obj[team] = _obj[team] || {}
            let obj = _obj[team]

            key = key || '0';
            total = (+total || 0) * mult;
            obj[key] = obj[key] || {total: 0}
            if (withItems) {
                obj[key].items ??= []
                obj[key].items.push(item);
            }

            if (details) {
                obj[key].details ??= {}
                obj[key].details[details?._id] ??= {}
                obj[key].details[details?._id][details?.ind] = true
            }


            if (withTotals) {
                obj[key].totals ??= []
                obj[key].totals.push(total);
            }

            if (total > 0) {
                obj[key].totalTo = (obj[key].totalTo || 0) + (total > 0 ? total : 0);

            }
            if (total < 0) {
                obj[key].totalFrom = (obj[key].totalFrom || 0) + (total < 0 ? total : 0);

            }

            obj[key].total += (total || 0);
        })

    };

    _.each(transactions, (item, ind) => {

        _.each(item?.items, (it, ind) => {
            if (filterFinalItem && !filterFinalItem(it)) {
                return
            }
            let {
                type,
                account, team, period
            } = it;
            let amount = it[amountKey || 'amount']

            // if (amount > 0) {
            //
            // } else if (amount < 0) {
            //
            // }

            let keys = keysFn(it, opts, item);

            teamsObj[team] = true;
            contractsObj[item.contract] = true;

            let alreadyKeys = {}
            _.each(keys, (keyItem, ind) => {
                let key = keyItem?.key || keyItem;
                if (!key) {
                    return;
                }

                if (alreadyKeys[key]) {
                    return;
                }
                alreadyKeys[key] = true;
                let rub = toRub(amount, account, period)

                let fn = onPushFn || onPush;
                fn(stats, key, keyItem?.teamFn ? keyItem.teamFn(it, item) : team, it, rub, {_id: item._id, ind: ind})
            })

        })
    })

    stats.contracts = Object.keys(contractsObj).filter(it => it && it !== 'undefined')
    stats.teams = Object.keys(teamsObj).filter(it => it && it !== 'undefined')
    return stats
}


export function gv(_id, itemsKey, defValue = '-', pubKey = 'name', items2Key, items3Key) {
    let itemsObj = window.info[itemsKey + 'Obj']
    return ((itemsObj || {})[_id] || {})[pubKey] || (((window.info[items2Key + 'Obj'] || {})[_id] || {})[pubKey])
        || (((window.info[items3Key + 'Obj'] || {})[_id] || {})[pubKey]) || defValue || _id || '-'
}

export let pubAuthor = (v) => gv(v, 'authors')
export let pubCategory = (v) => gv(v, 'categories')
export let pubPosition = (v) => gv(v, 'positions')
export let pubStatus = (v2) => {
    return (STATUS_ITEMS.filter(v => {
        return v?.value == v2
    })[0] || {}).name || '-'
}
export let pubTeam = (v) => gv(v, 'allTeams')
export let pubType = (v) => gv(v, 'types', '', 'name', 'deepTypes', 'subTypes')
export let pubemployee = (v) => {
    if (!+v) {
        return '-'
    }
    let empl = window?.info['employeesObj'][v] || {}
    let agentSnip = empl.workerType == 'agent' ? ' (A)' : ''
    return empl.surName
    + ' ' + ((empl.name || '')[0] || '').toUpperCase() + '.' + agentSnip
}
export let pubUser = (v) => gv(v, 'users', '', 'username')
export let pubPaidStatus = (v) => gv(v || 'wait', 'paidStatuses')
export let pubEmployee = pubemployee;
export let pubAccount = (v) => gv(v, 'accounts')
export let pubContract = (v) => gv(v, 'contracts')

export let statusObj = {
    name: 'Статус',
    key: 'status',
    field: 'status',
    type: 'select',
    items: ['', 'active', 'unactive']
}


export function setHash(data, str) {
    str = str || JSON.stringify(data)
    // history.push({})
    console.log("qqqqq str", str);
    window.history.pushState({}, '', `#${str}`);
}

export function getHash(def) {

    try {
        let str = decodeURI((window.location.hash || '').replace(/^\#/gi, ''));
        let v = JSON.parse(str)

        // console.log("qqqqq vvvvvvvvvvvvvv filter", v, str);
        return {...def || {}, ...v || {}}
    } catch (e) {
        return {...def || {}}
    }
}

export function capitalizeFirstLetter(string) {
    string = string || ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}