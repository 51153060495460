//parseTransactions
// pubTransactions
// addItemsToTransactions
import {toUniqueArr} from "../Main/TopStats";
import {getVirtualAccount} from "../Main/methods";
import _ from 'underscore'
import {incPeriod} from "../Main/PeriodsSelector";

function getCurrency(account) {
    return (window.info.accountsObj[account] || {}).currency || 'RUR';

}

function getKeffs(period) {
    let it = window?.periodStats || {};
    return it?.currency || {};
}


export function getCurrencyK(account, period) {
    let keffs = getKeffs(period);
    let currency = getCurrency(account) || '';
    return keffs[currency] || 1;
}


export function toRub(amount, account, period) {
    amount = +amount || 0
    let k = 1; //getCurrencyK(account, period) || 1
    return amount * k
}

export function toRubPub(number, round) {
    return Math.round(1000 * 1000* (+number || 0), round).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

export function toRubByCurrency(amount, currency, account) {
    amount = +amount || 0
    let keffs = getKeffs();
    let k = keffs[currency]

    console.log("qqqqq keffs", keffs, currency);
    return amount * k
}

export function safeNum(v, def) {
    return +v || +def || 0;
}


export function generateHistorySource(items, cb, ecb) {
    let prevEl = {};

    let fields = {
        prihod: ['To'],
        perevod: ['From', 'To'],
        perevod_smart: ['From', 'To'],
        nalog_office: ['From', 'To'],
    }

    function getSmallItem(item, key, fieldProps) {
        key = key || ''
        let k = key === 'From' ? -1 : 1;
        let account = item['account' + key];
        let deltaPeriodTo = fieldProps?.yearPremiaPeriodTo;
        let deltaPeriodFrom = fieldProps?.yearPremiaPeriodFrom

        let subType = item.type === 'trati' ? item.subType : ''
        let it = {
            deltaPeriodTo,
            deltaPeriodFrom,
            isShadow: item.isShadow,
            amount: k * (+item['amount' + key] || 0) || 0,
            team: item['team' + key] || 0,
            account: account || 0,
            employee: item.employee || 0,
            type: subType || item.type,
            period: item.period,
            periodFrom: item.periodFrom,
            periodTo: item.periodTo,
            currency: getCurrency(account),
            status: item.status,
            position: item.position,
            // employeeShadow: item.employeeShadow || 0,
            contract: item.contract || 0,
            date: item.date,
            periodsCount: item.periodsCount,
            isKorpNalog: item.isKorpNalog,

        }


        let v = Object.keys(it).reduce((acc, key) => {
            if (key === 'amount') {
                acc[key] = +it[key] || 0
                return acc;
            }
            let value = it[key] && it[key] != 0 ? it[key] : (prevEl || {})[key];
            if (!value) {
                return acc;
            }
            key === 'team' && console.log("qqqqq vvvvvvvvvvvvvvvvvv1111111111111111 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", value);
            acc[key] = +value == value ? +value : value;
            return acc;
        }, {})

        // if (v.periodsCount > 1) {
        if (deltaPeriodTo) {
            v.periodTo = incPeriod(v.periodTo || v.period, deltaPeriodTo || 0)
        }
        if (deltaPeriodFrom) {
            v.periodFrom = incPeriod(v.periodFrom || v.period, deltaPeriodFrom || 0)
        }
        if (deltaPeriodTo || deltaPeriodFrom) {
            v.periodsCount = (+deltaPeriodTo || 0) + (+deltaPeriodFrom || 0)
        }


        v.amount = v.amount || 0
        v.amountMonthly = +((v.amount || 0) / (v.periodsCount || 1)).toFixed(3)


        return v;
    }

    let pubIt = (it, obj, fieldProps) => {

        if (!it) {
            return null
        }

        let item = {
            ...obj || {}, ...it || {}
        };

        // item.amountFrom = (+item.amountPlainFrom || 0) + (+item.amountNalogFrom || 0)

        let itemsArr = []


        let type = item.type;

        let myFields = fields[type] || ['From']


        _.each(myFields, (field, ind) => {

            // if (+item['amount' + field]) {
            itemsArr.push({
                ...getSmallItem(item, field, fieldProps)
            })
            // }
        })

        prevEl = {...prevEl || {}, ...itemsArr.at(-1) || {}};

        console.log("qqqqq itemssssssssssssssssss arrrrrrrrrrrrrrrrrrrr", itemsArr);
        return itemsArr;
    };

    let genHist = (it) => {
        let keys = {
            'oklad': ['premia', 'oklad_nalog', 'premia_nalog'],
            'contract': ['korp_nalog'],
            // 'prihod': ['prihod', 'prihod_korp']
        };
        // if (it?.special?.isYearPremia) {
        //     keys.oklad = [...keys.oklad,
        //         {key: 'year_premia', yearPremiaPeriodFrom: 0, yearPremiaPeriodTo: +it.special?.yearPremiaPeriodTo || 6},
        //         {key: 'year_premia_nalog'}]
        // }

        let dbItems = it?.special?.dbItems || {};

        if (it.type == 'nalog_office') {
            let virtaulAccount = getVirtualAccount();
            // item.accountTo = item.accountTo || virtaulAccount?._id
            // item.accountFrom = item.accountFrom || virtaulAccount?._id
            it.accountTo = it.accountTo || virtaulAccount?._id
            it.accountFrom = it.accountFrom || virtaulAccount?._id
            // } else if (it.type == 'prihod') {

        }

        _.each(['accountFrom', 'accountTo', 'teamFrom', 'teamTo', 'employee', 'employeeShadow', 'contract', 'team'], (key, ind) => {
            it[key] = it[key] || 0
        })
        _.each(['virtaulAccount1', 'virtualAccount2'], (key, ind) => {
            console.log("qqqqq itttttttttttttttt", it, it.special);
            it.special = it.special || {}
            it.special[key] = it.special[key] || 0
        })


        let arr = [];
        let type = it.type
        if (type === 'prihod') {
            let amount = safeNum(it.amountTo);
            let korp_perc = safeNum(it.special.korp_perc) || 15;
            let korp_fix = safeNum(it.special.korp_fix);
            let nalogK = getCurrencyK(it.special.korp_account, it.period)

            let nalogAmmount = safeNum(amount * korp_perc / 100) + safeNum(korp_fix) / nalogK;

            let nalogAmmountRub = toRub(safeNum(amount * korp_perc / 100) + safeNum(korp_fix) / nalogK, it.accountTo);
            // let accountK =
            let nalogAmmountCurrency = toRub(safeNum(amount * korp_perc / 100) + safeNum(korp_fix), it.accountTo);
            let profitAmmount = amount - nalogAmmount;


            let shadowValue = safeNum(profitAmmount * safeNum(it.special.shadow_perc, 30) / 100)

            console.log("qqqqq shadowValue", shadowValue, amount, it.employeeShadow, it);
            if (+it.employeeShadow) {
                arr = [
                    getSmallItem({
                        ...it, type: 'prihod',
                        amountTo: profitAmmount - shadowValue
                    }, 'To'),
                    getSmallItem({
                        isShadow: 1,

                        ...it,
                        type: 'prihod', employee: it.employeeShadow,
                        teamTo: it.teamToShadow || it.teamTo,
                        amountTo: shadowValue
                    }, 'To'),
                ]
                console.log("arrrrrrrrrrrrrrrrrrrrr", arr, it)
            } else {
                arr = [getSmallItem({...it, type: 'prihod', amountTo: profitAmmount}, 'To')]
            }
            if (nalogAmmountRub) {

                arr.push(
                    getSmallItem({
                        isKorpNalog: 1,

                        ...it, type: 'prihod_nalog',
                        accountTo: it.special.korp_account,
                        teamTo: it.special.korp_team,
                        amountTo: nalogAmmountRub / nalogK,
                    }, 'To')
                )

                console.log("qqqqq kkkkkkkkkkkkkkkkkkkkkkkkkkk", nalogK, nalogAmmountRub, arr);
            }
        } else if (type == 'oklad') {

            let v = pubIt(it)[0];
            let nalog_v = getNalogValue(v.amount, it)
            let dbPremia = it.special?.dbItems?.premia || {};
            let premia = -1 * dbPremia?.amountFrom || 0;
            arr = [

                getSmallItem({
                    ...v, type: 'oklad',
                }, ''),
                getSmallItem({
                    ...v, type: 'oklad_nalog',
                    amount: nalog_v
                }, ''),
                getSmallItem({
                    ...v, type: 'premia',
                    amount: premia,
                    account: dbPremia.accountFrom,
                }, ''),
                getSmallItem({
                    ...v, type: 'premia_nalog',
                    amount: getNalogValue(premia, it?.special?.dbItems?.premia),
                    account: dbPremia.accountFrom,
                }, ''),
            ]
            if (it?.special?.isYearPremia) {
                let dbPremia = it?.special?.dbItems?.year_premia
                let year_premia = -1 * dbPremia?.amountFrom;
                let account = dbPremia?.accountFrom;

                console.log("qqqqq year_premiayear_premia", year_premia, dbPremia, account);

                arr = [...arr, getSmallItem({
                    ...v, type: 'year_premia',
                    amount: year_premia,
                    account,
                }, ''),
                    getSmallItem({
                        ...v, type: 'year_premia_nalog',
                        amount: getNalogValue(year_premia, dbPremia),
                        account
                    }, ''),]
            }
            arr = arr.filter(it => it.amount)
        } else if (type == 'raspredi') {
            let v = pubIt(it)[0];

            _.each(it.special.raspredItems || [], (item, ind) => {
                let {amount, account, employee, nalog_k} = item;
                amount = (-1) * +amount || 0;
                let nalog_v = getNalogValue(amount, item);

                let vv1 = getSmallItem({
                    ...v, type: 'raspredi',
                    amount,
                    employee,
                    account,
                });

                console.log("qqqqq itttttttttttttttttttttttttttt", item, {amount, account, nalog_v}, vv1);

                arr.push( vv1)
                nalog_v && arr.push( getSmallItem({
                    ...v, type: 'raspredi_nalog',
                    amount: nalog_v,
                    employee,
                    account,
                }, ''))
            })

        } else {
            arr = [pubIt(it), ...(keys[it.type] || []).map(v => {
                let key = v.key || v;
                let pullObj = v.pullKey ? dbItems[v.pullKey] || {} : {};
                let vv = {
                    type: key,
                    position: it.position,
                    status: it.status,
                    accountFrom: pullObj.accountFrom,
                    accountTo: pullObj.accountTo
                };

                return pubIt(dbItems[key], vv, v)
            })].flat().filter(it => it);
        }

        console.log("qqqqq arrrrrrrrrrrrrrrrrrrrrrrr", arr);
        let isVirtual = (it) => {
            return (it?.type === 'perevod_smart' || it?.type == 'perevod') && it?.special?.virtualAccount1;
        }


        if (isVirtual(it)) {
            let vAccountFrom = it?.special?.virtualAccount1;
            let vAccountTo = it?.special?.virtualAccount2 || it?.special?.virtualAccount1;
            arr.push({
                ...prevEl || {},
                isVirtual: true,
                type: 'virtual_perevod',
                account: vAccountFrom,
                amount: toRub(it.amountFrom, it.accountFrom, it.period),
                team: it.teamFrom,
            })
            arr.push({
                ...prevEl || {},
                type: 'virtual_perevod',
                isVirtual: true,
                account: vAccountTo,
                amount: toRub(-1 * it.amountTo, it.accountTo, it.period),
                team: it.teamTo,
            })
        }
        // console.log('*........ ## arrrrrrrr', arr, JSON.stringify(arr, null, 4), arr, keys);


        return arr;
    };

    _.each(items, (it, ind) => {
        it = getMonthDifference(it)
        let _items = genHist(it);
        let _teams = toUniqueArr(_items, 'team')
        items[ind].items = _items;
        items[ind].teams = _teams;
        items[ind].accounts = toUniqueArr(_items, 'account');
        items[ind].employees = toUniqueArr(_items, 'employee');
        console.log("qqqqq aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", items[ind].accounts, _items);
    });

    return items;
}


function getPeriod(_cd) {
    let cd = new Date(_cd);
    let pub = (v) => v < 10 ? '0' + v : v;
    let period = [cd.getFullYear(), pub(cd.getMonth() + 1)].join('-')
    // console.log("qqqqq cd", _cd, cd, period);
    return period
}

function getMonthDifference(item) {
    item.date = item.date || new Date().getTime();
    item.period = item.period || getPeriod(item.date || item.cd)

    if (item.periodFrom && item.periodTo && (item.periodFrom > item.periodTo)) {
        let temp = item.periodFrom;
        item.periodFrom = item.periodTo;
        item.periodTo = temp;
    }

    let startDate = item.periodFrom
    let endDate = item.periodTo

    let {period} = item

    const start = startDate ? new Date(startDate) : new Date(period);
    const end = endDate ? new Date(endDate) : new Date(period);

    let months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth();
    months += end.getMonth();

    // Return the absolute value of the difference
    item.periodsCount = Math.abs(months) + 1;
    // console.log("qqqqq itttttttttttttttttttttttt", item.periodsCount, months, item.period, item.date);
    return item;
}


export function getNalogValue(amount, nalog_obj) {
    let kk = {
        'ooo': 0.25,
        'ndfl': 0.15,
    }

    let v = kk[nalog_obj?.nalog_k] || 0
    let r = (-1) * Math.abs(amount * v) || 0;
    console.log("qqqqq get nalog value", r, amount, nalog_obj);
    return r
}