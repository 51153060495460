import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";
import Button from "../../libs/Button";
import Smart from "../../libs/Smart";
import {getter} from "../methods/Fetcher";


function Layout2(props) {
    let [open, setOpen] = useState(false)
    let {item, url, field, onChange} = props
    let gc = getter(('/' + url).replace("//", '/'));
    let fn = window.info[field + 'Update'];
    let fn2 = window.info[field + 'Delete'];


    let onCreateUpdate = (cb) => {

        if (!item._id) {
            gc.create(item, (it) => {
                console.log("qqqqq create author", it);
                cb && cb();
                fn && fn(it)
                onChange(it)
            })
        } else {
            gc.update(item, (it) => {
                cb && cb();
                fn && fn(item)
                onChange(it)
            })
        }
    }

    if (!item) {
        return <></>
    }

    return <div>
        <Button onClick={(scb) => {
            onCreateUpdate(scb)
        }}>{item?._id ? 'Обновить' : 'Создать'}</Button>
        <MyModal
            size={'small'}
            isOpen={open}
            onClose={setOpen}
        >
            Уверены, что хотите удалить запись?
            <div className={'mt10'}>
                {item.name || item.title || '-'}
            </div>
            <hr/>

            <Button onClick={(scb) => {
                gc.delete(item, () => {
                    fn2 && fn2(item)
                    scb && scb();
                    onChange(false)
                })
            }}>Удалить</Button><Button onClick={(scb) => {
                scb && scb()
            setOpen(false)
            }} color={4}>Отменить</Button>
        </MyModal>
        {item._id && <Button color={4} onClick={(cb) => {
           setOpen(true)
            cb && cb()
        }}>Удалить</Button>}
    </div>
}

export default Layout2
