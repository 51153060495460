import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import MyModal from "../../libs/MyModal";
import Button from "../../libs/Button";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import Input from "../../libs/Input";
import {regify} from "./Categories";
import {CompactPicker} from 'react-color';
import {ListOfAuthors} from "./ZametkiPreview";

function Layout2(props) {
    let [isOpen, setIsOpen] = useState(null)
    let [selAuthor, setSelAuthor] = useState(null)
    let [search, setSearch] = useState('')

    console.log('*........ ## ROOT RENDER', props);

    let reg = regify(search)
    return <div>
        <a onClick={() => {
            setIsOpen(true)
        }}>Авторы</a>

        <MyModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
            {selAuthor && <MyModal
                size={'small'}
                isOpen={selAuthor}
                onClose={() => setSelAuthor(false)}
            >
                Редактировние автора
                <Smart
                    defSize={12}
                    obj={selAuthor}
                    items={[{
                        key: 'name',
                        name: 'Имя',
                        type: 'input',
                    }, {
                        key: 'short',
                        name: 'Аббревиатура',
                        type: 'input',
                    }, {
                        key: 'img',
                        name: 'Урл фото',
                        type: 'input',
                    },
                    ]}
                    onChange={(v) => {
                        setSelAuthor({...v})
                    }}
                ></Smart>
                <div style={{opacity: selAuthor.img ? .1 : 1}}>
                    <small>Цвет</small>
                    <div></div>
                    <CompactPicker color={selAuthor.color} onChangeComplete={(color) => {
                        console.log("qqqqq color", color);
                        setSelAuthor({...selAuthor, color: color.hex})
                    }}/>
                    <div></div>
                    <small>Цвет Текста</small>
                    <div></div>
                    <CompactPicker color={selAuthor.colorText} onChangeComplete={(colorText) => {
                        setSelAuthor({...selAuthor, colorText: colorText.hex})
                    }}/>
                </div>
                <div style={{marginTop: '20px'}}></div>
                <ListOfAuthors items={[selAuthor]}></ListOfAuthors>
                <hr/>
                <SaveUpdate
                    onChange={() => setSelAuthor()}
                    item={selAuthor} url={'author'} field={'authors'}></SaveUpdate>

            </MyModal>}
            <Button onClick={(cb) => {
                setSelAuthor({})
                cb && cb();
            }}>+ Автор</Button>
            <hr/>
            <Input value={search} onChange={setSearch} placeholder={'Поиск'}></Input>
            {(window.info?.authors || []).filter(it => {
                return !reg || reg.test(it.name)
            }).map((it, ind) => {
                return (<div key={ind} onClick={() => setSelAuthor(it)}>
                    <ListOfAuthors items={[it]}></ListOfAuthors>
                    <div className="ib ml-5">{it.name || '-'}</div>
                </div>)
            })}
        </MyModal>


    </div>
}

export default Layout2
