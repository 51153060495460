
export const STATUS_ITEMS = [
    {name: 'Бэклог', value: 'backlog'},
    {name: 'БэклогБлок', value: 'backlog_block'},
    {name: 'Спринт', value: 'sprint'},
    {name: 'Процесс', value: 'process'},
    {name: 'Пинг', value: 'ping'},
    {name: 'Ревью', value: 'review'},
    {name: 'Сделано', value: 'done'},
    {name: 'Архив', value: 'archive'},
]
