import React, {useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import {getter} from "../methods/Fetcher";
import MyModal from "../../libs/MyModal";
import Input from "../../libs/Input";
import MyAutocomplete from "./MyAutocomplete";
import {pubCategory} from "./methods";
import Stringify from "../Stringify";
import SaveUpdate from "./SaveUpdate";

let gc = getter('/category');

export function regify(str) {
    return str ? new RegExp(str, 'gi') : null
}

function Layout2(props) {
    // console.log('*........ ## ROOT RENDER', props);
    let [search, setSearch] = useState('');
    let [selCategory, setSelCategory] = useState(null);

    let {filter, setFilter} = props;

    let addCategory = (parentId, cb) => {
        setSelCategory({parentId})
        cb && cb();
    }


    // let v = useActionData();
    let reg = regify(search);
    let filteredCategories = window?.info?.categories.filter(it => {
        return !reg || reg.test(it.name)
    })

    let onClick = (parentId) => {
        setFilter({...filter || {}, category: parentId})
    }
    let onEdit = (it) => {
        setSelCategory(it)
    }
    let catObj = {}
    return <>
        {selCategory && <MyModal
            isOpen={selCategory}
            onClose={() => setSelCategory(null)}
        >

            <MyAutocomplete
                label={'Категория Родительская'}
                itemsKey={'categories'}
                value={selCategory?.parentId}
                onChange={(it) => {
                    console.log("qqqqq on change category", it);
                    setSelCategory({...selCategory || {}, parentId: it || -1})
                }}
            ></MyAutocomplete>
            {/*<div className="col-xs-12">*/}
            {/*    {selCategory?.parentId}*/}
            {/*</div>*/}
            <Input value={selCategory?.name} placeholder="Названиe" onChange={(name) => {
                setSelCategory({...selCategory || {}, name})
            }}></Input>
            <hr/>
            <SaveUpdate
                onChange={() => setSelCategory()}
                item={selCategory} url={'category'} field={'categories'}></SaveUpdate>
        </MyModal>}
        <div></div>
        <Button
        size={'xs'}
        color={4}
            onClick={(cb) => {
            addCategory(-1, cb)
        }}>
            + топ
        </Button>
        <Button
        size={'xs'}
        color={4}
        onClick={(cb) => {
            addCategory(filter.category || -1, cb)
        }}>
            + текущий
        </Button>
        <Input type="text"
               woLabel={1}
               placeholder={'Поиск по категориям'}
               value={search} onChange={search => {

            setSearch(search)
        }}/>
        <hr/>
        <RenderTreeItem _id={-1}
                        search={search}
                        reg={reg}
                        categories={filteredCategories}
                        category={filter.category}
                        onClick={onClick}
                        catObj={catObj}
                        onEdit={onEdit}>
        </RenderTreeItem>
        <RenderOthers categories={filteredCategories}
                      onClick={onClick}
                      onEdit={onEdit}
                      category={filter.category}
                      catObj={catObj}></RenderOthers>
    </>
}

function RenderOthers(props) {
    let {categories, catObj, onClick, onEdit} = props;
    let items = (categories || []).filter(it => {
        return !catObj[it._id]
    })
    if (!items.length) {
        return <></>
    }
    return <>
        <div>
            <small>После поиска (другие перенты)</small>
        </div>
        {items.map((it, ind) => {
            let {_id} = it;
            return (<div key={ind} className={'ellipse w100 ' + (props.category === _id ? 'selected' : '')} onClick={() => onClick(_id)}>
                <div className="pull-right" onClick={() => {
                    props?.onEdit && props?.onEdit(it)
                }}>
                    <div className="fa fa-pencil"></div>
                </div>

                <>{pubCategory(_id) || _id} ({pubCategory(it.parentId)})</>
            </div>)
        })}
    </>
}


function RenderTreeItem(props) {
    let {_id, reg, catObj} = props;
    let categories = props.categories || window?.info?.categories

    return <>
        <div
            className={'w100 ellipse ' + (props.category === _id ? 'selected' : '')}
            onClick={() => props.onClick && props.onClick(_id)}>
            {_id != -1 && <><div className="pull-right" onClick={() => {
                props?.onEdit && props?.onEdit(window.info?.categoriesObj[_id])
            }}>
                <div className="fa fa-pencil"></div>
            </div></>}
            {pubCategory(_id) || _id}


        </div>
        <div className={'categoryWrap'}>
            {(categories || []).filter(it => {
                if ((it.parentId || -1) != _id) {
                    return false;
                }
                return true;
            }).map((it, ind) => {
                catObj[it._id] = true;
                return (<div key={ind}>

                    <RenderTreeItem {...props} _id={it._id}></RenderTreeItem>
                </div>)
            })}
        </div>
    </>
}


export default Layout2
