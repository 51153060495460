import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {capitalizeFirstLetter, pubAuthor, pubCategory} from "./methods";
import Stringify from "../Stringify";
import ZadachiEdit from "./ZadachiEdit";
import ZadachiDate from "./ZadachiDate";


export function TaskDate(props) {
    let {item} = props;
    let it = item;


    return <div key={props.ind} className={'titleWrap'} onClick={() => {
        props.onClick && props.onClick(it)
    }}>
        {/*<div className="pull-right">*/}
        {/*    <div className="fa fa-pencil"></div>*/}
        {/*</div>*/}
        <div className={'titleName'}>
            {it.name || '-'}
        </div>

        {it.desc && <div className={'titleDesc'}>
            {it.desc || '-'}
        </div>}
        {it?.info?.solutionCreteria && <div className={'titleDesc'}>
            {capitalizeFirstLetter(it?.info?.solutionCreteria || '')}
        </div>}
        <div className="woShort">
            <ZadachiDate item={it}></ZadachiDate>
            <hr/>
        </div>
    </div>
}

export default TaskDate
