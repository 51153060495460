import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {pubAuthor, pubCategory, pubStatus} from "./methods";
import Stringify from "../Stringify";
import ZadachiEdit from "./ZadachiEdit";
import {ListOfAuthors} from "./ZametkiPreview";
import {ToRub} from "./TopStats";
import {toRubPub} from "../methods/GenerateHistory";

function isEvenWeek(date) {
    // Copy date to not modify the original
    date = new Date(date.getTime());

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));

    // Calculate week number
    let yearStart = new Date(date.getFullYear(), 0, 1);
    let weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

    // Return true if week number is even, false if odd
    return weekNo % 2 === 0;
}

export function getODBByInfo(info, periodDelta = 0) {
    periodDelta = +periodDelta || 0
    let DAY = 1000 * 24 * 3600;

    if (!info) {
        return ''
    }
    if (info?.periodType != 'process') {
        return new Date(new Date().getTime() + (periodDelta + 1) * DAY).toODB();
    }

    let deltaDays = (delta) => {
        delta = +delta;
        return (delta && delta != 0 ? delta - 1 : 0) * DAY;
    }
    let startOfMonth = (_date, delta, periodDelta) => {
        let v = new Date(_date.getFullYear(), _date.getMonth() + periodDelta, 1, 10).getTime();

        return new Date(v + deltaDays(delta))
    }

    function startOfQuarter(date, delta, periodDelta) {
        const month = date.getMonth();
        const quarterStartMonth = Math.floor(month / 3) * 3;

        let v = new Date(date.getFullYear(), quarterStartMonth, 1, 10);
        // v.setMonth(-3)
        v.setMonth(v.getMonth() + periodDelta * 3);

        return new Date(v.getTime() + deltaDays(delta));
    }

    function startOfWeek(date, delta = 0, periodDelta) {

        let resultDate = new Date(date);
        // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
        let day = resultDate.getDay();
        // Calculate the difference to the nearest Monday
        let diff = (day === 0 ? -6 : 1) - day;

        let v2 = resultDate.getTime() + diff * DAY + deltaDays(delta) + periodDelta * 7 * DAY;
        console.log("qqqqq diffdiffdiffdiff", diff, delta, date, resultDate.getTime(), v2, new Date(v2), delta);
        // Copy date to not modify the original
        // date = new Date(date);
        // const day = date.getDay();
        // const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek;
        //
        // date.setDate(date.getDate() - diff);
        // date.setHours(10, 0, 0, 0); // Set time to the start of the day

        return new Date(v2);
    }


    let cd = new Date()
    let delta = info.periodDay || 0;

    if (info.period === 'w') {
        return startOfWeek(cd, delta, periodDelta).toODB()
    }
    if (info.period === 'm') {
        return startOfMonth(cd, delta, periodDelta).toODB()
    }
    if (info.period === 'w2') {
        let isEven = isEvenWeek(cd);
        return startOfWeek(cd, +delta + (((isEven ? 1 : 2) == info?.periodWeek) ? 7 : 0), (periodDelta) * 2).toODB()
    }
    if (info.period === 'q') {
        return startOfQuarter(cd, delta, periodDelta).toODB()
    }
    // let date = new Date();
    // let _startOfWeek = startOfWeek(date, )
    // console.log(startOfWeek);
    return ''
}

let CD_ODB = new Date().toODB();
export function TaskDate(props) {
    let {item} = props;
    let it = item;

    let {period, remindDays, periodWeek = 1, periodDay = 0, periodType = 'one'} = item?.info || {};

    let {items, itemsPeriod} = item || {};
    let {date, minDate, maxPeriodDate} = item?.dates || {};
    let info = item?.info || {};

    let expOdb = getODBByInfo(item.info)
    if (expOdb > CD_ODB) {
        expOdb = getODBByInfo(item.info, -1)
    }
    console.log("qqqqq itennnnnnnnnnnnnnnnnnnnnn", item.dates, item);

    let odb1 = date.remindDate ? new Date(date.remindDate).toODB() : '';
    let odb0 = date.date ? new Date(date.date).toODB() : '';

    let isError = expOdb > odb0;
    let isWarn = expOdb > odb1;
    // let currentOdb =
    let isProcess = info.periodType == 'process';
    let status = item?.info?.status;
    return <div>

        <div className="label label-info">
            {isProcess ? 'Процесс' : 'Разовая'}
            {!isProcess ? ` ${items.filter(it => {
                return /done|archive/gi.test(it.status)
            }).length} из ${items.length}` : ''}
        </div>
        <div className={"label " + (/done|archive/gi.test(status) ? 'label-success' : /block/gi.test(status) ? 'label-danger' : 'label-info')}>
            {pubStatus(status)}
        </div>
        <div className="label label-default">
            {toRubPub(info.profit3 || 0)}
        </div>
        <div className="label label-default">
            {toRubPub(info.profit6 || 0)}
        </div>
        <div className="label label-default">
            {toRubPub(info.profit24 || 0)}
        </div>

        {(it.categories || []).map((it, ind) => {
            return (<a key={ind} className={'ib ml-5 linkCat'}>
                {pubCategory(it)}
            </a>)
        })}

        {it?.authors && !!it?.authors?.length && <ListOfAuthors items={it.authors}></ListOfAuthors>}
        <div></div>
        <div className={'label ' + (isError ? 'label-danger' : isWarn ? 'label-warning' : 'label-default')}>
            {odb0 || '--'} {odb1 && odb1 != odb0 && <>({odb1})</>}
        </div>
        {(info?.myWorkTime || info.myPerc) && <div className="label label-default">

            {info.myWorkTime}ч x {info.myPerc}%
        </div>}
        {info.teamWorkTime && <div className="label label-default">
            {info.teamWorkTime}ч x {info.teamPerc}% ({info.teamWorkTimeControl}ч)
        </div>}
        {<div className="label label-default" title={'Профит 6м * 12'}>
            {toRubPub(((6 * +(info.profit6 || 0) ) * ( +info.myPerc / 100 || 0) / (+info.myWorkTime || 1)))} р/ч
        </div>}

        {/*<div className="label label-success">*/}
        {/*    {expOdb}*/}
        {/*</div>*/}
        {/*PD: {new Date(maxPeriodDate.date).toODB()} {maxPeriodDate.remindDays} = {new Date(maxPeriodDate.remindDate).toODB()}*/}
    </div>
}

export default TaskDate
