import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {pubAuthor, pubCategory} from "./methods";
import Stringify from "../Stringify";
import ZadachiEdit from "./ZadachiEdit";
import ZadachiDate from "./ZadachiDate";
import ZadachiPreview from "./ZadachiPreview";
import './Zadachi.css'

export function groupItems(originalItems, filter, sortItems, type, limit) {
    type = type || 'zametki'
    let {category, search, authors} = filter || {};
    category = filter?.category || -1;
    if (category == -1) {
        // category = -100
    }
    let reg = search ? new RegExp(search, 'gi') : null;

    let items = [];
    let childItems = [];
    let {childs, fullChilds} = window?.stats || {};
    let childsObj = childs[category] || {}
    let fullChildsObj = fullChilds[category] || {}


    let limit1 = 0;
    let limit2 = 0;
    limit = limit || 100;
    _.each(originalItems, (item, ind) => {
        if (type === 'task' && filter.filt && filter?.filt != item.status) {

        }

        if (type === 'task' && filter.status) {
            let isActive = filter.status == 'notArchive' && !/done|archive/gi.test(item?.info?.status)

            type == 'task' && console.log("qqqqq fitlttttttttttttttttt", type, filter.status,
                item?.info?.status, isActive);

            if ((filter.status != item?.info?.status) && !isActive) {
                return false;
            }

        }
        if (reg && (!reg.test(item.name) && !reg.test(item.desc))) {
            return false;
        }
        if (authors && authors.length) {
            let isOk = false;
            _.each(authors, (filterAuthor, ind) => {
                _.each(item.authors, (itemAuthor, ind) => {
                    if (filterAuthor == itemAuthor) {
                        isOk = true;
                    }
                })
            })

            if (!isOk) {
                return false;
            }

        }
        if (category > 0) {
            let isOk = false;
            let isOkFull = false;
            _.each(item.categories, (cat, ind) => {
                if (cat == category) {
                    isOk = true
                    // items.push(item)
                } else if (fullChildsObj[cat]) {
                    // childItems.push(item)
                    isOkFull = true;
                }
            })

            if (isOk) {
                ++limit1 < limit && items.push(item)
            } else if (isOkFull) {
                ++limit2 < limit && childItems.push(item)
            }
        } else {
            ++limit1 < limit && items.push(item)
        }
    })

    type === 'task' && console.log("qqqqq itemssssssssssssss", items, filter, type, originalItems);

    let sortObj = {
        fastprofit: (it) => {
            let info = it.info || {};
            return (-1) * (info.profit3 || 0)
        },
        profit: (it) => {
            let info = it.info || {};
            let profit = (-1) * (info.profit24 || 0) - 1 * ((info.profit3 || 0) / 1000) - 1 * ((info.profit6 || 0) / 10);
            return profit
        },
        myHours: (it) => {
            let info = it.info || {};
            console.log("profitttttttttttttttttt", info.myWorkTime, info)

            return +info.myWorkTime || 0
        },
        date: (it) => {
            let info = it.info || {};
            return it?.dates?.date?.date || '00';
        },
        profitTime: (it) => {
            let info = it.info || {};
            return (-1) * (+info.profit6 || 0) / (+info.myWorkTime || 1)
        }

    }
    let fn = sortObj[filter.sort] || sortObj['fastprofit']
    sortItems = sortItems || fn;
    // if (filter.sort)

    return {items: _.sortBy(items, sortItems), childItems: _.sortBy(childItems, sortItems), category}
}

let MAIN_ZADACHI_CB = null;

function Layout2(props) {
    let {filter} = props
    console.log('*........ ## ROOT RENDER', props);

    let [selZadacha, setSelZadacha] = useState(null)
    let {category} = filter || {};

    let v = groupItems(window?.info?.tasks || [], filter, null, 'task')
    let {items, childItems} = v;
    console.log("qqqqq vvvvvvvvvvvvvvvvvv", v, window?.info?.tasks, filter);
    // let items = _.sortBy(window?.info?.tasks.filter(it => {
    //     if (category > 0) {
    //         let isOk = false;
    //         _.each(it.categories, (item, ind) => {
    //             if (item == category) {
    //                 isOk = true;
    //             }
    //         })
    //
    //         return isOk;
    //     }

    //     return true;
    // }), it => {
    //     return (-1) * (it.info?.level || 1)
    // })

    window.onOpenTask = (item, cb) => {
        MAIN_ZADACHI_CB = cb;
        setSelZadacha(item)
    }

    return <div>
        <Button
            size='xs'
            color={4}
            onClick={(cb) => {
                setSelZadacha({categories: category > 0 ? [category] : []})
                cb && cb()
            }}>+ Задача</Button>
        <hr/>
        <div className="vh100">
            {(items || []).map((it, ind) => {
                return <ZadachiPreview ind={ind} item={it} onClick={setSelZadacha}></ZadachiPreview>
            })}
            {!!childItems && !!childItems.length && <>
                <hr/>
                <div>Задачи из дочерних</div>
                <hr/>
                {(childItems || []).map((it, ind) => {
                    return <ZadachiPreview ind={ind} item={it} onClick={setSelZadacha}></ZadachiPreview>
                })}
            </>}
            {/*<hr/>*/}
            {/*<ZadachiEdit item={selItem} onChange={setSelItem} onClose={() => {*/}
            {/*    setSelZadacha(false)*/}
            {/*}}></ZadachiEdit>*/}
        </div>
        <MyModal
            size={'full'}
            isOpen={selZadacha}
            onClose={setSelZadacha}
        >

            <ZadachiEdit item={selZadacha} onChange={setSelZadacha} onClose={(v) => {
                MAIN_ZADACHI_CB && MAIN_ZADACHI_CB(v)
                MAIN_ZADACHI_CB = null;
                setSelZadacha(false)
            }}></ZadachiEdit>
        </MyModal>


    </div>
}

export default Layout2
