import React, {useState, useEffect} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Button from "../../libs/Button";
import MyModal from "../../libs/MyModal";
import Smart from "../../libs/Smart";
import SaveUpdate from "./SaveUpdate";
import {capitalizeFirstLetter, pubAuthor, pubCategory} from "./methods";
import Stringify from "../Stringify";
import ZadachiEdit from "./ZadachiEdit";
import ZadachiDate from "./ZadachiDate";
import GroupButton from "../../libs/GroupButton/GroupButton";


export function ListOfAuthors(props) {
    let {items} = props;

    return <div className={'authorsList'}>
        {(items || []).map((it, ind) => {
            let a;
            if (/string|number/gi.test(typeof it)) {
                a = window?.info?.authorsObj[it];
            } else {
                a = it;
            }


            let name = a.short || (a.name || '').substring(0, 2)
            return (<a key={ind} className={'ib ml-5 linkCat authorsItem'}
                       title={a.name || a.short}
                       style={{background: a.color, color: a.colorText || 'black'}}
            >
                {name}
            </a>)
        })}
    </div>

}

export function TaskDate(props) {
    let {item, ind, onClick} = props;
    let it = item;
    let selZametka = item;

    return <div key={ind} className={'titleWrap'} onClick={() => {
        onClick && onClick(it)
    }}>
        <div className="pull-right text-right">
            <div className={'woShort'}>

                <div>
                    {(it.categories || []).map((it, ind) => {
                        return (<div key={ind} className={'linkCat'}>
                            <a>
                                {pubCategory(it)}
                            </a>
                        </div>)
                    })}
                </div>
                <div>
                    <div className="">
                        <GroupButton
                            color={4}
                            value={it?.info?.level || 0} items={[1, 2, 3, 4, 5].map(it => {
                            return {value: it, name: it}
                        })}></GroupButton>
                    </div>
                </div>
            </div>

            <div>
                <div className="pull-right mt5">
                    <ListOfAuthors items={it.authors}></ListOfAuthors>
                </div>
            </div>
        </div>

        {!!it?.tasks?.length && <div className={"pull-left mr5 label " + (it?.tasks?.length ? 'label-success' : 'label-danger')}
             style={{marginTop: '0'}}>
            x{it?.tasks?.length}
        </div>}
        {it?.status == 'archive' && <div className={"mr5 label label-warning"}>
            АРХИВ
        </div>}
            {capitalizeFirstLetter(it.name) || '-'}

            {it.desc && <div className={'titleDesc'}>
                {capitalizeFirstLetter(it.desc || '')}
            </div>}
        <div className="forImgZam">
            {selZametka?.img && <><img src={selZametka?.img}/></>}
            {selZametka?.img2 && <><img src={selZametka?.img2}/></>}
        </div>
        <div className="w100 pull-right">
            <hr/>
        </div>

    </div>
}

export default TaskDate
