import React from 'react';

let Fetcher = {

    // loading: {},
    data: {},

    onChange(url, opts) {
        if (!opts) {
            this.data[url] = null;
            return this.get(url)
        }
    },

    get(url, params, opts) {
        if (!this.data[url]) {

            this.data[url] = {curLoading: true};
            return new Promise((resolve, reject) => {
                global.http.get(url, params)
                    .then(r => {
                        resolve(r)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                function iter() {
                    if (this.data[url].curLoading) {
                        setTimeout(iter)
                    } else {
                        resolve(this.data[url])
                    }
                }

                iter();


            })
        }

    }
}


export function getter(url) {
    return {
        get: async (query, cb) => {
            return new Promise((resolve, reject) => {
                global.http.get(url, query).then(r => {
                    resolve(r.items, r)
                    cb && cb(r.items, r)
                });
            })
        },
        getOne: async (_id, cb) => {
            return new Promise((resolve, reject) => {
                global.http.get(url + "/" + _id).then(r => {
                    resolve(r.items, r)
                    cb && cb(r.items, r)
                });
            })
        },
        createUpdate: async (query, new_item, cb) => {
            console.log("qqqqq crate update", query);

            return new Promise((resolve, reject) => {
                global.http.post(url + '/find_create_update', {query, new_item}).then(r => {
                    resolve(r, r)
                    cb && cb(r, r)
                });

            })
        },
        delete: async (item, cb) => {
            return new Promise((resolve, reject) => {
                global.http.delete(url, {_id: item._id}).then(r => {
                    resolve(r, r)
                    cb && cb(r, r)
                });
            })
        },
        update: async (item, cb) => {
            return new Promise((resolve, reject) => {
                global.http.put(url, item).then(r => {
                    resolve(r, r)
                    cb && cb(r, r)
                });
            })
        },
        create: async (item, cb) => {
            return new Promise((resolve, reject) => {
                global.http.post(url, item).then(r => {
                    resolve(r, r)
                    cb && cb(r, r)
                });
            })
        },

    }
}


export default Fetcher

